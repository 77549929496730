
.module-content {
    display: flex;
    justify-content: space-between;
    padding: 19px 20px;
    /*position: absolute;*/
    /*bottom: 20px;*/
    /*left: 0;*/
    /*right: 0;*/
    align-items: center;
    box-shadow: 0px -5px 17px 3px rgba(4, 132, 70, 0.1);
    position: relative;
    background: #FFFFFF;

    .module {
        flex: 1;
        width: 1%;

        ::v-deep .el-scrollbar__wrap {
            overflow-y: hidden;
        }

        ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
            white-space: nowrap;
        }
    }

    .module-list {
        padding: 6px;
        display: flex;
        position: relative;

        .new-module-item {
            position: relative;
            width: 60px;
            height: 60px;
            border-radius: 10px;
            margin-right: 15px;
        }

        .module-item {
            position: relative;
            width: 60px;
            height: 60px;
            border: 1px dashed #CCCCCC;
            border-radius: 24px;
            margin-right: 13px;
        }

        .item-cover {
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 50%;

            i {
                font-size: 20px;
                color: #fff;
            }
        }

        .item-cover1 {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            border-radius: 50%;

            i {
                font-size: 20px;
                color: #fff;
            }

            .show-img {
                width: 100%;
                height: 100%;
                box-shadow: 0px 2px 9px 1px rgba(87, 77, 237, 0.17);
                border-radius: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                .img-box {
                    width: 50px;
                    height: 50px;
                    border-radius: 24px;
                }
            }
        }

        .show1 {
            background: #61BCEB;
        }

        .show2 {
            background: #3DCAC8;
        }

        .show3 {
            background: #F0BC49;
        }

        .show4 {
            background: #EC75A4;
        }

        .show5 {
            background: #79ACDC;
        }

        .show6 {
            background: #61BCEB;
        }

        .show7 {
            background: #5AB9E9;
        }

        .courseware {
            background: linear-gradient(0deg, #F5D971, #EDA92E);
        }

        .textbook {
            background: linear-gradient(0deg, #7BCBF2, #44ACE3);
        }

        .lesson-plan {
            background: linear-gradient(0deg, #53DED1, #2BB9BF);
        }

        .micro-lesson {
            background: linear-gradient(0deg, #EE89B5, #EA5D90);
        }

        .animation {
            background: linear-gradient(0deg, #F39E72, #EC6C6C);
        }

        .test-center {
            background: linear-gradient(0deg, #8CC5EC, #5F8AC7);
        }

        .training-center {
            background: linear-gradient(0deg, #ED83AA, #EC6B6F);
        }

        .knowledge-points {
            background: linear-gradient(0deg, #77C9F1, #47AEE4);
        }

        .material-library {
            background: linear-gradient(0deg, #51DCD0, #2CBAC0);
        }

        .case-library {
            background: linear-gradient(0deg, #F5D971, #EDA92E);
        }

        .related-copywriting {
            background: linear-gradient(0deg, #8BC3EB, #628EC9);
        }

        .personal-info {
            background: linear-gradient(0deg, #51DCD0, #2FBDC1);
        }

        .del-btn {
            width: 18px;
            height: 18px;
            background: #FF0000;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            position: absolute;
            right: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 1;
        }

        .show-title {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
            cursor: pointer;
        }

        .new-module-item:before {
            content: "";
            width: 0;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            opacity: 0;
        }


        .new-module-item:hover:before {
            width: 100%;
            left: 0;
            opacity: 0.5;
        }

        .new-module-item:hover .del-btn {
            opacity: 1;
        }
    }

    .module-save-btn {
        width: 60px;
        height: 60px;
        background: #2DC079;
        border-radius: 10px;
        color: #FFFFFF;
        font-size: 14px;
        //line-height: 60px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        font-weight: 400;
        //margin-right: 20px;

        &:hover {
            cursor: pointer;
        }
    }

    .adhibition-btn {
        width: 60px;
        height: 60px;
        background: #2DC079;
        border-radius: 10px;
        color: #FFFFFF;
        font-size: 14px;
        text-align: center;
        font-weight: 400;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 20px;
    }
}

.temp-popover {
    display: flex;
    flex-direction: column;

    .no-data {
        text-align: center;
    }

    .popover-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 30px;

        &:hover {
            background: #ECF5FF;

            .dragg-item {
                color: #2DC079;
            }

            span {
                color: #409EFF;
            }
        }

        div {
            color: #666666;
            font-size: 14px;
            padding-left: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        i {
            color: #666666;
            font-size: 12px;
            padding-right: 10px;
        }
    }
}

.select-positioning {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    box-shadow: 0px 1px 6px 1px rgba(64, 158, 255, 0.2);
    border-radius: 10px;
    margin-left: 20px;
    //position: absolute;
    //right: -20px;
    //top: -78px;
    //z-index: 99;
    background: #2DC079;

    i {
        font-size: 29px;
        color: #9CA9C3;
    }

    .hover-two {
        background: #2DC079;
        border-radius: 10px;
        color: #FFFFFF;
        font-size: 14px;
        //line-height: 60px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        font-weight: 400;
        cursor: pointer;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

::v-deep .saveTemplate {
    width: 500px;

    .el-dialog__header {
        background: #2DC079;

        span {
            color: #fff;
            font-size: 16px;
        }

        .el-icon-close {
            color: #fff;
        }
    }

    .el-dialog__body {
        padding: 70px 0px 20px 50px;
    }

    .template-box {
        display: flex;
        flex-direction: column;

        .template-box-name {
            display: flex;
            align-items: center;
            color: #333333;
            font-size: 14px;
            padding-right: 60px;
        }

        .template-box-btn {
            margin-top: 70px;
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;

            .el-button {
                margin-left: 20px;
            }
        }
    }
}
